import React from "react";
import classes from "./Footer.module.scss";
import { Trans, useTranslation } from "react-i18next";
import logo from '../../../assets/img/logo_black.svg'

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className={`${classes.footerWrap} mt`}>
      <div className="container">
        <div className={classes.footer}>
          <img className={classes.logo} src={logo} alt='' />
          <div className={`${classes.contacts} font-17`}>
            <a href="tel:+61884231247">
              +61884231247
            </a>
            <a href="tel:+41315087527">
              +41315087527
            </a>
            <a href={`mailto:support@${hostname}`}>
              support@wealth-advisory.info
            </a>
          </div>
          <div className={`${classes.docs} font-17`}>
            <a href="/docs/CH-ID_CH-660-1160004-1.pdf" target="_blank">
              CH-ID CH-660-1160004-1
            </a>
            <a href="/docs/CHE-111.690.550.pdf" target="_blank">
              CHE-111.690.550
            </a>
            <a href="/docs/STATUTS_DE.pdf" target="_blank">
              STATUTS DE
            </a>
            <a href="/docs/PTY_LTD.pdf" target="_blank">
              PTY LTD
            </a>
          </div>
          <div className={classes.about}>
            <div className={classes.block}>
              <p>
                {t('footer.disclaimer.text_01')}
              </p>
              <p>
                <Trans>
                  {t('footer.disclaimer.text_03')}
                </Trans>
              </p>
            </div>
            <div className={classes.rights}>
              {t('footer.rights')}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
